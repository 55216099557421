<template>
	<div>
		<div class="flex header">
			<h2>黑名单</h2>
		</div>
		<div class="table">
			<el-table
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 256px)"
					style="width: 100%">
				<el-table-column
						prop="carNo" align="center" show-overflow-tooltip
						label="车牌号码">
				</el-table-column>
				<el-table-column
						prop="blackLevel" align="center" show-overflow-tooltip
						label="黑名单级别">
					<!--<template slot-scope="scope">
						{{ scope.row.blackLevel == 1 ? "Ⅰ" : scope.row.blackLevel == 2 ? "Ⅱ" : "Ⅲ" }}级
					</template>-->
				</el-table-column>
				<el-table-column
						prop="blackReason" align="center" show-overflow-tooltip
						label="拉黑原因">
				</el-table-column>
				<el-table-column
						prop="chargeParking" align="center" show-overflow-tooltip
						label="违禁停车场">
				</el-table-column>
				<el-table-column
						prop="memberType" align="center" show-overflow-tooltip
						label="用户类型">
				</el-table-column>
				<el-table-column
						prop="carOwner" align="center" show-overflow-tooltip
						label="车主姓名">
				</el-table-column>
				<el-table-column align="center" fixed="right"
												 label="操作" width="150px">
					<template slot-scope="scope">
						<el-button class="update" @click="relieve( scope.row)">解除黑名单</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<el-dialog
				top="0" center class="commonDia updateMem" :close-on-click-modal="false"
				:visible.sync="joinBlack" :show-close="false"
				width="300px" :close-on-press-escape="false">
			<div class="joinBlack">
				<img src="../../assets/img/error.png" alt="">
				<p>{{ blackCarNo }}</p>
				<p>是否确认<br>将该会员解除黑名单</p>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="joinBlack=false">取 消</el-button>
				<el-button type="primary" @click="doRemoveBlack">确 定</el-button>
			</div>
		</el-dialog>

	</div>

</template>

<script>
import { getBlackMemberList,removeBlack } from "@/api/api";

export default {
	name: "blackList",
	data() {
		return {
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			joinBlack: false,
			blackCarNo: "",
            memberId:""
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			let params = {
				page: this.page.current,
				size: this.page.size
			};
			getBlackMemberList(JSON.stringify(params)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		relieve(row) {
			this.blackCarNo = row.carNo;
			this.memberId = row.id;
			this.joinBlack = true;
		},
        doRemoveBlack(){
            removeBlack(this.memberId).then(res => {
                //移出成功
				if(res.data.code === 200){
                    this.joinBlack = false;
                    this.getData();
					this.$message.success(res.data.msg);
				}else{
                    this.$message.error(res.data.msg?res.data.msg:'操作失败');

                }
            })/*.catch(err => {
                console.log("err: " + err);
            });*/
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
